import { Menu } from "./menu.model";

export const verticalMenuItems = [
  new Menu(1, "Dashboard", "/dashboard", null, "dashboard", null, false, 0),
  // new Menu(
  //   2,
  //   "Administrator",
  //   "/admin",
  //   null,
  //   "supervisor_account",
  //   null,
  //   false,
  //   0
  // ),
  new Menu(3, "Sites", "/sites", null, "map", null, false, 0)
];
