import { Component, OnInit } from '@angular/core';

import { AppService } from './app.service';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public loading = false;
  public settings: Settings;

  constructor(
    public appSettings: AppSettings,
    public appService: AppService) {
      this.settings = this.appSettings.settings;

     this.appService
        .loadingSpinnerSubject
        .subscribe((loading) =>
        setTimeout(() => this.loading = loading));
  }

  public ngOnInit() {
    // this.appService.loadingSpinner = false;
  }
}
