import { Component, ElementRef, Inject, ViewChild, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-spikes-threshold',
  templateUrl: './spikes-threshold.component.html',
  styleUrls: ['./spikes-threshold.component.scss']
})
export class SpikesThresholdComponent implements OnInit {


  constructor(private fb: FormBuilder, private matDialogRef: MatDialogRef<SpikesThresholdComponent>, ) { }


  thresholdForm: FormGroup;

  ngOnInit() {
    this.thresholdForm = this.fb.group({
      count: ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.min(1)]],
    })
  }

  close() {
    if (this.thresholdForm.valid) {
      var spikeData = this.thresholdForm.get('count').value
      sessionStorage.setItem('spikeData', spikeData);
      this.matDialogRef.close();
    }

  }

  dialogClose() {
    this.matDialogRef.close();
  }

}
