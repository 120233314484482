import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    DateAdapter,
    MAT_DATE_LOCALE,
    MAT_DATE_FORMATS,
} from '@angular/material';
import { RouterModule } from '@angular/router';


import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { DeleteDialogComponent } from './component/delete-dialog/delete-dialog.component';
import { InformationViewComponent } from './component/information-view/information-view.component';
import { ViewDownloadComponent } from './component/view-download/view-download.component';
import { ContentHeaderComponent } from './content-header/content-header.component';
import { SpikesThresholdComponent } from './component/spikes-threshold/spikes-threshold.component'
import { SnackbarComponent } from './snackbar/snackbar.component';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CustomDateFormat } from './component/date-format';
// import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

// Import angular-fusioncharts
import { FusionChartsModule } from "angular-fusioncharts";

// Import FusionCharts library and chart modules
import * as FusionCharts from "fusioncharts";
import * as Charts from "fusioncharts/fusioncharts.charts";

import * as FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

// Pass the fusioncharts library and chart modules
FusionChartsModule.fcRoot(FusionCharts, Charts, FusionTheme);

import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgxChartsModule } from '@swimlane/ngx-charts';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    FusionChartsModule,
    DateRangePickerModule,
    NgxChartsModule
  

  ],
  exports: [
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    ContentHeaderComponent,
    BreadcrumbComponent,
    DeleteDialogComponent,
    ViewDownloadComponent,
    FormsModule,
    ReactiveFormsModule,
    SpikesThresholdComponent,
    FusionChartsModule,
    DateRangePickerModule,
    NgxChartsModule

  ],
  declarations: [
    ContentHeaderComponent,
    BreadcrumbComponent,
    DeleteDialogComponent,
    ViewDownloadComponent,
    InformationViewComponent,
    SnackbarComponent,
    SpikesThresholdComponent
  ],
  entryComponents: [
    ViewDownloadComponent,
    DeleteDialogComponent,
    InformationViewComponent,
    SnackbarComponent,
    SpikesThresholdComponent
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: CustomDateFormat }
  ]
})
export class SharedModule {}
