import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Site } from 'src/app/model/site.model';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent {
  componentName: string;

  message: string;

  @ViewChild('cancelBtn')
  cancelBtn: ElementRef;

  model: Site;

  constructor(
    private matDialogRef: MatDialogRef<DeleteDialogComponent>,

    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.componentName = data.componentName;

    this.message = data.message;

    this.model = data.model;
    this.model.erased =  true;
    console.log(this.componentName);
    console.log(this.message);
    console.log(this.model);
  }

  onDelete() {
        this.model
          .setData(Object.assign({}, this.model.getData()))
          .then(() => this.model.commit())
          .then(() =>  this.matDialogRef.close(true));
  }
}
