import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-information-view',
  templateUrl: './information-view.component.html',
  styleUrls: ['./information-view.component.scss']
})
export class InformationViewComponent implements OnInit {
  public selectedValue: any;

  public mode: any;

  public component: any;

  public lists: any;

  public selectedData: any;

  public mainInfoList: any;

  public mainInfoValue: any;

  public tableHeader = ['Days', 'Morning', 'Break', 'Evening'];

  public operatingHours = [];

  constructor(
    private matDialogRef: MatDialogRef<InformationViewComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    if (data) {
      this.selectedValue = data.selectedValue;
      this.mode = data.mode;
      this.component = data.component;
      this.lists = data.list;
      this.selectedData = data.selectedData;
      this.mainInfoList = data.mainInfoList;
      this.mainInfoValue = data.mainInfoValue;
      this.operatingHours = data.tableData;
    }
  }

  getDay(day) {
    if (day === 1) {
      return 'Sunday';
    }

    if (day === 2) {
      return 'Monday';
    }

    if (day === 3) {
      return 'Tuesday';
    }

    if (day === 4) {
      return 'Wednesday';
    }

    if (day === 5) {
      return 'Thrusday';
    }

    if (day === 6) {
      return 'Friday';
    }

    if (day === 7) {
      return 'Saturday';
    }

    return '-';
  }

  ngOnInit() {}
}
