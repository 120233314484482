import { Component, OnInit, Inject } from '@angular/core';
import { ExcelService } from 'src/app/pages/dashboard/info-cards/excel.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppService } from 'src/app/app.service';
import { Observable } from 'rxjs';
import {Paho} from 'ng2-mqtt/mqttws31';
import { environment } from 'src/environments/environment';
import * as dateformat from 'dateformat';
import { THIS_EXPR, IfStmt } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-mqtt-data',
  templateUrl: './mqtt-data.component.html',
  styleUrls: ['./mqtt-data.component.scss']
})
export class MqttDataComponent implements OnInit {
  gid$: Observable<any>;
  cid$: Observable<any>;
  fromdate = '';
  todate = '';
  private client;
  MQTTflowDATA = [];
  MQTTalarmDATA = [];
  finalmqttflowdata$: Observable<any> [];
  finalmqttalarmdata$: Observable<any> [];

  mqttbroker = environment.mqttURL;
  today = new Date();


  constructor(private matDialogRef: MatDialogRef<MqttDataComponent>,
    private appService: AppService,
   @Inject(MAT_DIALOG_DATA) data) {
    if (data) {
      this.gid$ = data.gatewayid;
      this.cid$ = data.componentid;
    }

   }


  ngOnInit() {
    this.client = new Paho.MQTT.Client(this.mqttbroker, Number(9001), '');
      this.client.onMessageArrived = this.onMessageArrived.bind(this);
      this.client.onConnectionLost = this.onConnectionLost.bind(this);
      this.client.connect({onSuccess: this.onConnect.bind(this),useSSL:true});

  }

  onConnect() {
    console.log('onConnect');
    this.client.subscribe('VA/FlowData/' + this.gid$ + '/' + this.cid$); // getting leakage
    this.client.subscribe('FA/LE/' + this.gid$ + '/' + this.cid$); // leak error
    this.client.subscribe('FA/CE/' + this.gid$ + '/' + this.cid$);  // comm error
    this.client.subscribe('test/' + this.gid$ + '/' + this.cid$);
    this.client.subscribe('testalarm/' + this.gid$ + '/' + this.cid$);
  }

  onConnectionLost(responseObject) {
    console.log('connection disconnected....');
    if (responseObject.errorCode !== 0) {
      console.log('onConnectionLost:' + responseObject.errorMessage);
    }
  }

  onMessageArrived(message) {
    console.log('onMessageArrived: ' + message.destinationName + ': ' + message.payloadString);
    const topic = message.destinationName.split('/');
    if (topic[0] === 'test') {
      const time = dateformat(new Date(), 'shortTime');
      this.MQTTflowDATA.push('TIME:' + time + ' DATA:' + message.payloadString);
      this.finalmqttflowdata$ = this.MQTTflowDATA;
      console.log(this.MQTTflowDATA);
      console.log(this.finalmqttflowdata$);

    }
    if (topic[0] === 'testalarm') {
      const time = dateformat(new Date(), 'shortTime');
      this.MQTTalarmDATA.push('TIME:' + time + ' TYPE:' + topic[1] + ' STATUS:' + message.payloadString);
      this.finalmqttalarmdata$ = this.MQTTalarmDATA;
      console.log(this.MQTTalarmDATA);
      console.log(this.finalmqttalarmdata$);

    }
    if (topic[1] === 'FlowData') {
      const time = dateformat(new Date(), 'shortTime');
      this.MQTTflowDATA.push('TIME:' + time + ' DATA:' + message.payloadString);
      this.finalmqttflowdata$ = this.MQTTflowDATA;
      console.log(this.MQTTflowDATA);
      console.log(this.finalmqttflowdata$);

    }
    if (topic[1] === 'LE') {
      const time = dateformat(new Date(), 'shortTime');
      this.MQTTalarmDATA.push('TIME:' + time + ' TYPE:' + topic[1] + ' STATUS:' + message.payloadString);
      this.finalmqttalarmdata$ = this.MQTTalarmDATA;
      console.log(this.MQTTalarmDATA);
      console.log(this.finalmqttalarmdata$);

    }
    if (topic[1] === 'CE') {
      const time = dateformat(new Date(), 'shortTime');
      this.MQTTalarmDATA.push('TIME:' + time + ' TYPE:' + topic[1] + ' STATUS:' + message.payloadString);
      this.finalmqttalarmdata$ = this.MQTTalarmDATA;
      console.log(this.MQTTalarmDATA);
      console.log(this.finalmqttalarmdata$);

    }




    // if (message.destinationName.indexOf('wind_speed') !== -1) {
    //   this.windSpeed = Number(message.payloadString);
    // }

    // if (message.destinationName.indexOf('wind_direction') !== -1) {
    //   this.windDirection = Number(message.payloadString);
    // }

  }


  close() {
    this.matDialogRef.close();
    this.MQTTflowDATA = [];
    this.MQTTalarmDATA = [];
    this.client.disconnect();
  }

}
