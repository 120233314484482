import { AgmCoreModule } from '@agm/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { CalendarModule } from 'angular-calendar';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { AppSettings } from './app.settings';
import { ErrorComponent } from './pages/errors/error/error.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { PagesComponent } from './pages/pages.component';
import { SharedModule } from './shared/shared.module';
import { FullScreenComponent } from './theme/components/fullscreen/fullscreen.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { MessagesComponent } from './theme/components/messages/messages.component';
import { SidenavComponent } from './theme/components/sidenav/sidenav.component';
import { TopInfoContentComponent } from './theme/components/top-info-content/top-info-content.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { PipesModule } from './theme/pipes/pipes.module';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';
import { AuthGuard } from './auth.guard';
import { MqttDataComponent } from './pages/sites/block/apartment/component-details/mqtt-data/mqtt-data.component';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB3HQ_Gk_XRt6KitPdiHQNGpVn0NDwQGMI'
    }),
    PerfectScrollbarModule,
    CalendarModule.forRoot(),
    SharedModule,
    PipesModule,
    MatProgressSpinnerModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    NotFoundComponent,
    ErrorComponent,
    TopInfoContentComponent,
    SidenavComponent,
    VerticalMenuComponent,
    FullScreenComponent,
    MessagesComponent,
    UserMenuComponent,
    MqttDataComponent,
  ],
  providers: [
    AppSettings,
    AppService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthGuard
  ],
  entryComponents: [
    MqttDataComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
