import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

import { Response } from './common/data/response';
import { SnackbarComponent } from './shared/snackbar/snackbar.component';

@Injectable()
export class AppService {
    public loadingSpinnerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private snackBar: MatSnackBar) {}

    public set loadingSpinner(value: boolean) {
        this.loadingSpinnerSubject.next(value);
    }

    public onError(error) {
        const err = error instanceof Error ?
          { message: error.message } : this.getErrorMessage(error);

        setTimeout(() => {
          this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 2000,
            panelClass: ['failure-msg'],
            data: err.response || err.message || 'An Unknown Error! please try again'
          });
        });
    }

    private getErrorMessage(error) {
      return error.responseText || { message:  error.messages[0].text };
    }
}
