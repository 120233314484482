import {
  Component,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  AfterViewInit
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarDirective
} from 'ngx-perfect-scrollbar';

import { AppSettings } from '../app.settings';
import { Settings } from '../app.settings.model';
import { MenuService } from '../theme/components/menu/menu.service';
import { rotate } from '../theme/utils/app-animation';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  animations: [rotate],
  providers: [MenuService]
})
export class PagesComponent implements OnInit, AfterViewInit {
  @ViewChild('sidenav')
  sidenav: any;

  @ViewChild('backToTop')
  backToTop: any;

  @ViewChildren(PerfectScrollbarDirective)
  pss: QueryList<PerfectScrollbarDirective>;

  public optionsPsConfig: PerfectScrollbarConfigInterface = {};

  public settings: Settings;

  public showSidenav = false;

  public showInfoContent = false;

  public toggleSearchBar = false; // declared for return default menu when window resized

  public menus = 'vertical';

  public menuTypes = 'compact';

  constructor(
    public appSettings: AppSettings,
    public router: Router,
    private menuService: MenuService
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.optionsPsConfig.wheelPropagation = false;
    if (window.innerWidth <= 960) {
      this.settings.menu = 'vertical';
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.settings.loadingSpinner = false;
    }, 300);

    this.backToTop.nativeElement.style.display = 'none';
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.scrollToTop();
      }
      if (window.innerWidth <= 960) {
        this.sidenav.close();
      }
    });
    if (this.settings.menu === 'vertical') {
      this.menuService.expandActiveSubMenu(
        this.menuService.getVerticalMenuItems()
      );
    }
  }

  public closeInfoContent(showInfoContent) {
    this.showInfoContent = !showInfoContent;
  }

  @HostListener('window:resize')
  public onWindowResize(): void {
    if (window.innerWidth <= 960) {
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
      this.settings.menu = 'vertical';
    } else {
      this.settings.sidenavIsOpened = true;
      this.settings.sidenavIsPinned = true;
    }
  }

  public onPsScrollY(event) {
    event.target.scrollTop > 300
      ? (this.backToTop.nativeElement.style.display = 'flex')
      : (this.backToTop.nativeElement.style.display = 'none');
  }

  public scrollToTop() {
    this.pss.forEach(ps => {
      if (ps.elementRef.nativeElement.id === 'main') {
        ps.scrollToTop(0, 250);
      }
    });
  }

  public closeSubMenus() {
    if (this.settings.menu === 'vertical') {
      this.menuService.closeAllSubMenus();
    }
  }
}
