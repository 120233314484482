import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-view-download",
  templateUrl: "./view-download.component.html",
  styleUrls: ["./view-download.component.scss"]
})
export class ViewDownloadComponent implements OnInit {
  @ViewChild("f") viewDownloadForm: NgForm;

  selectedList: string;

  selectedReport: string;

  reportList: string;

  modeSelect: boolean;

  showList = [
    "Both Employees and Dependents",
    "Employees only",
    "Dependents only"
  ];

  showCardDetails = ["All Cards", "Card Number Keyed in Below"];

  employeeStatus = ["All Status", "Active Only", "Terminate/Old Only"];

  months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  reportTypes = [
    "Average Visit By Employee Stat",
    "Average Visit By Clinic Stat",
    "Average Cost Diagnosis By Clinic",
    "Average Cost Diagnosis By Company"
  ];

  reportOrders = ["Order By Utilization", "Order By Visit", "Order By MC"];

  constructor(
    private matDialogRef: MatDialogRef<ViewDownloadComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.selectedList = data.selectedList;
    this.selectedReport = data.selectedReport;
    this.reportList = data.reportList;
    this.modeSelect = data.modeSelect;
  }

  ngOnInit() {
    console.log(this.reportList);
  }

  onSubmit() {
    this.matDialogRef.close();
  }

  onCancel() {
    this.matDialogRef.close();
  }
}
