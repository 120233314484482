import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent {
  public settings: Settings;
  constructor(public appSettings: AppSettings, public router: Router) {
    this.settings = this.appSettings.settings;
  }

  goHome(): void {
    this.router.navigate(['/']);
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit() {
    this.settings.loadingSpinner = false;
  }
}
