import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material';


@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent {

  snackBarData:string;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.snackBarData=data;
   }

  ngOnInit() {    
  }

}
