import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { ErrorComponent } from './pages/errors/error/error.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { PagesComponent } from './pages/pages.component';
import { AuthGuard } from './auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: './pages/dashboard/dashboard.module#DashboardModule',
        data: { breadcrumb: 'Dashboard' },
        canActivate: [AuthGuard]
      },
      // {
      //   path: "admin",
      //   loadChildren:
      //     "./pages/administrator/administrator.module#AdministratorModule",
      //   data: { breadcrumb: "Administrator" }
      // },
      {
        path: 'sites',
        loadChildren: './pages/sites/sites.module#SitesModule',
        data: { breadcrumb: 'Sites' },
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'login',
    loadChildren: './pages/login/login.module#LoginModule'
  },
  // {
  //   path: "register",
  //   loadChildren: "./pages/register/register.module#RegisterModule"
  // },
  {
    path: 'error',
    component: ErrorComponent,
    data: { breadcrumb: 'Error' }
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
