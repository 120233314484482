import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './service/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor (private route: Router, private authservice: AuthService) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      // tslint:disable-next-line: no-unused-expression
      this.authservice.validates;
    if (localStorage.getItem('accessToken') != null) {
      return true;

    } else {
      this.route.navigate(['/login']);
      return false;
    }
  }
}
