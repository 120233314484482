import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpClient: HttpClient) { }
  validates(username: string, password: string) {
    return new Promise((resolve, reject) => {
      if (username.length > 0 && password.length > 0) {
        const loginDetails = { username: username, password: password };
        console.log("enviroment",environment.baseURL)
        this.httpClient.post(`${environment.baseURL}AdminPanel/login`, loginDetails).subscribe(
          (data: any) => {
            if (data && data.token) {
              localStorage.setItem('accessToken', data.token);
              localStorage.setItem('Access', data.role);
              resolve(true);
            } else {
              reject(false);
            }
          }, error => {
            reject(false);
          }
        );
      } else {
        reject(false);
      }
    })
  }
}
